<template>
  <TunnelLayout>
    <template #form>
      <div class="flex flex-col space-y-4">
        <div class="flex flex-col space-y-2">
          <h2 class="font-bold text-2xl text-promy-blue-300 sm:text-base">
            Êtes-vous propriétaire d'un autre bien?
          </h2>
          <validation-provider
            class="flex flex-col mb-4"
            name="form.informations.has_other_bien"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="flex gap-4">
              <pro-radio
                label="Oui"
                :value="form.informations.has_other_bien"
                @change="change($event)"
              >
                Oui
              </pro-radio>
              <pro-radio
                label="Non"
                :value="form.informations.has_other_bien"
                @change="change($event)"
              >
                Non
              </pro-radio>
            </div>
            <div class="w-full">
              <span
                v-show="errors[0]"
                class="mt-1 text-xs text-promy-red-400"
                >{{ errors[0] }}</span
              >
            </div>
          </validation-provider>
        </div>
        <div
          v-if="form.informations.has_other_bien === 'Oui'"
          class="flex flex-col space-y-2"
        >
          <h2 class="font-bold text-2xl text-promy-blue-300 sm:text-base">
            Est-ce un bien constructible ?
          </h2>
          <validation-provider
            class="flex flex-col mb-4"
            name="form.informations.has_other_bien"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="flex gap-4">
              <pro-radio
                label="Oui"
                :value="form.informations.has_bien_constructible"
                @change="changeConstructible($event)"
              >
                Oui
              </pro-radio>
              <pro-radio
                label="Non"
                :value="form.informations.has_bien_constructible"
                @change="changeConstructible($event)"
              >
                Non
              </pro-radio>
            </div>
            <div class="w-full">
              <span
                v-show="errors[0]"
                class="mt-1 text-xs text-promy-red-400"
                >{{ errors[0] }}</span
              >
            </div></validation-provider
          >
        </div>
        <div
          v-if="
            form.informations.has_other_bien === 'Oui' &&
            form.informations.has_bien_constructible === 'Oui'
          "
          class="flex flex-col space-y-2 items-baseline w-full"
        >
          <ProTextarea
            label="Décrivez le type de bien que vous possédez"
            class="w-full"
            placeholder="Dites-nous"
            v-model="form.informations.autre_bien_constructible"
          />
        </div>
        <div class="flex flex-col space-y-2 items-baseline w-full">
          <h2 class="font-bold text-2xl text-promy-blue-300 sm:text-base mt-8">
            Avez-vous des informations complémentaires à nous apporter ?
          </h2>
          <div class="flex mt-8 space-x-4 items-baseline w-full">
            <ProTextarea
              class="w-full"
              placeholder="Dites-nous"
              v-model="form.informations.more_informations"
            />
          </div>
        </div></div
    ></template>
    <template #buttons> <Buttons :form="form" :goNext="invalid" /></template>

    <template slot="right-side">
      <Map :showBtnParcelle="false" :form="form" :cadastre="cadastre" />
    </template>
  </TunnelLayout>
</template>
<script>
import Buttons from './components/Buttons.vue'
import Map from './components/Map.vue'

export default {
  components: {
    Buttons,
    Map,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    cadastre: {
      type: Object,
      require: true,
    },
    invalid: Boolean,
  },
  methods: {
    change(value) {
      this.form.informations.has_other_bien = value
      this.form.informations.has_bien_constructible = null
    },
    changeConstructible(value) {
      this.form.informations.has_bien_constructible = value
    },
  },
}
</script>
